import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ copyrights }) => (
  <footer>
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer

// {copyrights ? (
//     <div
//         dangerouslySetInnerHTML={{
//           __html: copyrights,
//         }}
//     />
// ) : (
//     <>
//         <span className="footerCopyrights">
//           © 2020
//         </span>
//     </>
// )}
